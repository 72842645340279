"use client";

import React, { CSSProperties } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  Box,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useBannerSlider from "@/hooks/fetchers/useBannerSlider";
import "./style.css";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";

type Props = {};

const indicatorStyles: CSSProperties = {
  background: "#fff",
  display: "inline-block",
  margin: "0 8px",
  cursor: 'pointer'
};

export default function LobbyCarousel({ }: Props) {
  const theme = useTheme();
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: bannersliders, isLoading: isBannerSliderLoading } = useBannerSlider();

  if (isBannerSliderLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Carousel
        // interval={6000}
        // infiniteLoop
        // autoPlay
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        centerMode
        centerSlidePercentage={33}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{
                  ...indicatorStyles,
                  borderRadius: theme.shape.borderRadius,
                  background: theme.palette.primary.contrastText,
                  width: 28,
                  height: 8,
                }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }

          return (
            <li
              style={{
                ...indicatorStyles,
                borderRadius: theme.shape.borderRadius,
                background: theme.palette.primary.main,
                width: 8,
                height: 8
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {bannersliders?.concat(bannersliders).concat(bannersliders)?.map((banner, index) => (
          <Box
            key={banner?.desc}
            sx={{
              marginRight: index + 1 === bannersliders?.concat(bannersliders).concat(bannersliders).length ? 0 : 1
            }}            
            // mx={{
            //   xs: 0,
            //   md: 0,
            // }}
          >
            <img
              style={{
                width: "100%",
                borderRadius: "16px",
              }}
              src={isMobile ? banner?.image.replace('.jpg', '_m.jpg') : banner?.image}
            />
          </Box>
        ))}
      </Carousel>
    );
  }
}
