"use client";

import React from "react";
import moment from "moment";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import QRCode from 'qrcode'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toPng } from 'html-to-image';

type Props = {
    elementRef: any;
};

const SaveHtmlButton = (props: Props) => {
    const saveImage = async () => {
        toPng(props.elementRef?.current, { cacheBust: false })
            .then((dataUrl) => {
                const now = moment().format("x");
                const link = document.createElement("a");
                link.download = `profile_${now}.png`;
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <CopyToClipboard text={''}>
            <Button
                startIcon={<Download />}
                onClick={saveImage}
                variant="outlined"
                color="primary"
                size="small"
                sx={{ fontWeight: 700, color: (theme: any) => theme.gradient[920] }}
            >
                คลิกเพื่อดาวน์โหลด
            </Button>
        </CopyToClipboard>
    );
};

export default SaveHtmlButton;
