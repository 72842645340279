"use client";

import * as React from "react";
import {
  Box,
  Divider,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import SignInForm from "@/sections/Login";
import { getUserServerSide } from "@/libs/auth";
import { redirect } from "next/navigation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { shallow } from "zustand/shallow";
import { useSnackbar } from "notistack";
// import WinWheel from "@/components/WinWheel/wheel";
import { useLuckyWheelStore } from "@/stores/luckyWheelStore";
import useMysteryBoxList from "@/hooks/fetchers/useMysteryBoxList";
import useMysteryBoxHistory from "@/hooks/fetchers/useMysteryBoxHistory";
import MysteryBox from "./MysteryBox";
import "./style.css";
import { useConfigStore } from "@/stores/configStore";
import { useAuthStore } from "@/stores/authStore";

const TYPES: {
  [key: string]: string;
} = {
  wheel_ticket: "ตั๋ว",
  wheel_credit: "ซื้อ",
  mystery_box: "ตั๋ว",
};

const MysteryBoxContent = () => {
  // const user = await getUserServerSide();
  // if (!user) {
  //   redirect("/login");
  // }

  const [user] = useAuthStore((state) => [state.user], shallow);
  const { data: wheels, isLoading: isWheelListLoading } = useMysteryBoxList();
  const { data: histories, isLoading: isWheelHistoryLoading } =
    useMysteryBoxHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [config] = useConfigStore((state) => [state.config], shallow);

  function createData(name: string, calories: string) {
    return { name, calories };
  }

  const rows = [createData(config?.mystery_box_max_winloss || "0", "1 ครั้ง")];

  return (
    <Box>
      <Box p={2}>
        <Box>
          {/* <Divider> */}
          <Typography
            className="neonText"
            variant="h5"
            fontWeight={"500"}
            align="center"
            color={(theme) => theme.gradient[900]}
          >
            กล่องสุ่ม
          </Typography>
          {/* </Divider> */}
        </Box>
        <Box mt={2}>
          {/* <SignInForm /> */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color={(theme) => theme.gradient[900]}>คลิกเพื่อดูเงื่อนไข</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{color: (theme) => theme.gradient[900] }}>สะสมยอดเสียครบ</TableCell>
                      <TableCell align="center" sx={{color: (theme) => theme.gradient[900] }}>สิทธิ์เปิดกล่องสุ่ม</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row" sx={{ color: (theme) => theme.gradient[900] }}>
                          {row.name}
                        </TableCell>
                        <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mt={2}>
          <MysteryBox />
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography sx={{ color: (theme) => theme.gradient[900] }}>{`ตั๋วฟรีคงเหลือ = ${user?.mystery_box_ticket ? 1 : 0}`}</Typography>
          </Stack>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography sx={{ color: (theme) => theme.gradient[900] }}>
              เปิดได้ {user?.mystery_box_ticket ? 1 : 0} ครั้ง
            </Typography>
          </Stack>
        </Box>
        <Box mt={3}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>วัน/เวลา</TableCell>
                  {/* <TableCell align="center">ประเภท</TableCell> */}
                  <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>จำนวน</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {histories
                  ? histories?.map((row: any) => (
                    <TableRow
                      key={row.created_at}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" component="th" scope="row" sx={{ color: (theme) => theme.gradient[900] }}>
                        {row.created_at}
                      </TableCell>
                      {/* <TableCell align="center">{TYPES[row.type]}</TableCell> */}
                      {/* <TableCell align="center">{row.type}</TableCell> */}
                      <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>{row.credit}</TableCell>
                    </TableRow>
                  ))
                  : histories}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default MysteryBoxContent;
