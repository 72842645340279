import { useAuthStore } from "@/stores/authStore";
import {
  Box, Chip,
  Divider,
  Typography,
  useTheme
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { shallow } from "zustand/shallow";
import { useUserProfile } from "@/hooks";
import SaveHtmlButton from "@/components/ui/SaveHtmlButton";
import { useRef } from "react";
import { useConfigStore } from "@/stores/configStore";
import { Logo } from "@/components/logo";

type Props = {};

const UserProfilePassword = (props: Props) => {
  const elementRef = useRef(null);
  const [user] = useAuthStore((state) => [state.user], shallow);
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    // user,
    // isBonus,
    // handleOpenModal,
  } = useUserProfile();
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);

  return (
    <Box>
      <div style={{ borderRadius: 16, marginTop: 16, marginBottom: 16 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#1a2c38',
            color: 'white',
            padding: '16px'
          }}
          ref={elementRef}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
          </div>
          <div style={{ marginBottom: '16px' }}>
            <Logo
              src={config?.logo || ""}
              height={theme.config.logo.height}
            />
          </div>
          <Divider>
            <Chip
              label="ข้อมูลส่วนตัว"
              color="primary"
              size="medium"
              style={{ fontSize: "18px", fontWeight: 700 }}
            />
          </Divider>
          <div style={{ marginTop: '16px' }}></div>
          <Typography
            variant="body1"
            lineHeight={1.5}
            mt={1}
          >
            {user?.bank_name}
          </Typography>
          <Typography variant="body1" lineHeight={0}>
            <Typography component={"span"} color={blue[200]}>
              เลขที่บัญชี:{" "}
            </Typography>
            {user?.account_number}
          </Typography>
          <Typography variant="body1" lineHeight={0}>
            <Typography component={"span"} color={blue[200]}>
              ชื่อ-นามสกุล:{" "}
            </Typography>
            {user?.fullname}
          </Typography>
          <Typography variant="body1" lineHeight={0} onClick={() => navigator.clipboard.writeText(user?.username || '')}>
            <Typography component={"span"} color={blue[200]}>
              เบอร์โทร:{" "}
            </Typography>
            {user?.username}
          </Typography>
          <Typography variant="body1" lineHeight={0} onClick={() => navigator.clipboard.writeText(user?.gameusername || '')}>
            <Typography component={"span"} color={blue[200]}>
              ยูสเซอร์:{" "}
            </Typography>
            {user?.gameusername}
          </Typography>
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
          </div>
        </div>
      </div>
      <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
        <SaveHtmlButton elementRef={elementRef} />
      </Box>
    </Box>
  );
};

export default UserProfilePassword;
